import React from "react"
import PolicySidebar from "../../components/body/pages/policy-sidebar"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Legal = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/legal/"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PolicySidebar />
  </Layout>
)

export default Legal
